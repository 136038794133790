<template>
  <b-container fluid>
    <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle> </template>
            <div class="p-2">
              <!-- <b-button
                @click="
                  $router.push('/helex/Capacitaciones/RegistroCapacitaciones')
                "
                variant="outline-danger"
                class="mx-1"
              >
                <i class="fas fa-chevron-left"></i>
                Regresar
              </b-button> -->
              <vs-button
                @click="
                  $router.push('/helex/Capacitaciones/RegistroCapacitaciones')
                "
                danger
                icon
                class="mx-1"
              >
                <i class="fas fa-chevron-left pr-1"></i>
                Regresar
              </vs-button>
            </div>
            <h4 class="px-4 py-4"><b>NUEVA CAPACITACIÓN</b></h4>
            <div class="row px-5 pb-5">
              <!-- nombre capacitacion -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 1</em> -
                  Nombre Capacitación</label
                >
                <b-form-input
                  v-model="newCapacitacion.nombre"
                  placeholder="Nombre capacitación..."
                  required
                ></b-form-input>
              </div>

              <!-- area capacitacion -->
              <div class="col-xl-6 mb-3">
                <div>
                  <label for=" " class="form-label">
                    <em class="text-danger font-weight-bolder">Paso 2</em> -
                    Seleccionar Area</label
                  >
                  <!-- vue-select -->
                  <div
                    :class="{ 'expanded-dropdown': shouldExpand }"
                    style="background: transparent; padding: 0rem"
                  >
                    <v-select
                      multiple
                      v-model="newCapacitacion.areasCapacitacionId"
                      class="style-chooser multiple"
                      :reduce="(value) => value.id"
                      :options="ListaAreasCapacitacion"
                      :get-option-label="(option) => `${option.descripcion}`"
                      placeholder="-- seleccionar area --"
                    >
                      <!-- required -->
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!newCapacitacion.areasCapacitacionId"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <!-- sin resultados en la busquedad -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para
                          <em class="text-danger">{{ search }}</em
                          >.
                        </template>
                        <em v-else style="opacity: 0.5"
                          >No hay areas para mostrar.</em
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>

              <!-- starDate -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 3</em> -
                  Seleccionar Fecha Inicio</label
                >
                <input
                  required
                  v-model="newCapacitacion.fechaInicio"
                  type="date"
                  class="form-control"
                  id=" "
                />
              </div>

              <!-- endDate -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 4</em> -
                  Seleccionar Fecha Fin</label
                >
                <input
                  required
                  v-model="newCapacitacion.fechaFin"
                  type="date"
                  class="form-control"
                  id=" "
                />
              </div>

              <!-- nombre instructor -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 5</em> -
                  Nombre Instructor</label
                >
                <b-form-input
                  v-model="newCapacitacion.nombreInstructor"
                  placeholder="Nombre instructor..."
                  required
                ></b-form-input>
              </div>

              <!-- duracion capacitacion -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 6</em> -
                  Duración Capacitación</label
                >
                <b-form-input
                  v-model="newCapacitacion.duracion"
                  type="number"
                  placeholder="Cantidad de horas..."
                  required
                ></b-form-input>
              </div>

              <!-- nombre empresa capacitadora -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 7</em> -
                  Nombre Empresa Capacitadora</label
                >
                <b-form-input
                  v-model="newCapacitacion.empresaCapacitadora"
                  placeholder="Nombre empresa..."
                  required
                ></b-form-input>
              </div>

              <!-- tipos de empresas -->
              <div class="col-xl-6 mb-3">
                <div>
                  <label for=" " class="form-label">
                    <em class="text-danger font-weight-bolder">Paso 8</em> -
                    Seleccionar Tipo Empresa</label
                  >
                  <!-- vue-select -->
                  <div style="background: transparent; padding: 0rem">
                    <v-select
                      v-model="newCapacitacion.tipoEmpresaId"
                      class="style-chooser"
                      :reduce="(value) => value.id"
                      :options="listaTiposEmpresa"
                      :get-option-label="(option) => `${option.descripcion}`"
                      placeholder="-- seleccionar tipo empresa --"
                    >
                      <!-- required -->
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!newCapacitacion.tipoEmpresaId"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <!-- sin resultados en la busquedad -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para
                          <em class="text-danger">{{ search }}</em
                          >.
                        </template>
                        <em v-else style="opacity: 0.5"
                          >No hay tipos de empresa para mostrar.</em
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>

              <!-- numero de documento empresa -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 9</em> -
                  Número Documento Empresa</label
                >
                <b-form-input
                  v-model="newCapacitacion.numeroDocumentoEmpresa"
                  placeholder="Número de documento empresa..."
                  required
                ></b-form-input>
              </div>

              <!-- tipo de documento -->
              <div class="col-xl-6 mb-3">
                <div>
                  <label for=" " class="form-label">
                    <em class="text-danger font-weight-bolder">Paso 10</em> -
                    Seleccionar Tipo Documento</label
                  >
                  <!-- vue-select -->
                  <div style="background: transparent; padding: 0rem">
                    <v-select
                      v-model="newCapacitacion.tipoDocumentoEmpresaId"
                      class="style-chooser"
                      :reduce="(value) => value.id"
                      :options="listaTipoDoc"
                      :get-option-label="(option) => `${option.descripcion}`"
                      placeholder="-- seleccionar tipo documento --"
                    >
                      <!-- required -->
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!newCapacitacion.tipoDocumentoEmpresaId"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <!-- sin resultados en la busquedad -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para
                          <em class="text-danger">{{ search }}</em
                          >.
                        </template>
                        <em v-else style="opacity: 0.5"
                          >No hay tipos de documentos para mostrar.</em
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>

              <!-- numero contrato empresa -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 11</em> -
                  Número Contrato</label
                >
                <b-form-input
                  v-model="newCapacitacion.numeroContrato"
                  placeholder="Número contrato empresa..."
                  required
                ></b-form-input>
              </div>

              <!-- fecha contrato -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 12</em> -
                  Seleccionar Fecha Contrato</label
                >
                <input
                  required
                  v-model="newCapacitacion.fechaContrato"
                  type="date"
                  class="form-control"
                  id=" "
                />
              </div>

              <!-- modalidad -->
              <div class="col-xl-6 mb-3">
                <div>
                  <label for=" " class="form-label">
                    <em class="text-danger font-weight-bolder">Paso 13</em> -
                    Seleccionar Modalidad</label
                  >
                  <!-- vue-select -->
                  <div style="background: transparent; padding: 0rem">
                    <v-select
                      v-model="newCapacitacion.modalidadCapacitacionId"
                      class="style-chooser"
                      :reduce="(value) => value.id"
                      :options="listaModalidadCapacitacion"
                      :get-option-label="(option) => `${option.descripcion}`"
                      placeholder="-- seleccionar modalidad --"
                    >
                      <!-- required -->
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!newCapacitacion.modalidadCapacitacionId"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <!-- sin resultados en la busquedad -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para
                          <em class="text-danger">{{ search }}</em
                          >.
                        </template>
                        <em v-else style="opacity: 0.5"
                          >No hay modalidades para mostrar.</em
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>

              <!-- costo total -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 14</em> -
                  Costo Total</label
                >
                <b-form-input
                  v-model="newCapacitacion.costoTotal"
                  type="number"
                  placeholder="Costo total capacitación..."
                  required
                ></b-form-input>
              </div>

              <!-- lista de usuarios -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 15</em> -
                  Seleccionar Asistentes
                  <span v-show="filteredAsistentes.length" class="text-primary"
                    >{{ selectedAsistentes.length }}/{{
                      filteredAsistentes.length
                    }}</span
                  ></label
                >
                <b-form-select
                  required
                  v-model="selectedAsistentes"
                  :select-size="8"
                  multiple
                  @change="onSelectedItemsChange"
                >
                  <template #first>
                    <b-form-select-option selected :value="null" disabled
                      >-- seleccionar asistentes --</b-form-select-option
                    >
                  </template>
                  <b-form-select-option
                    :value="it.id"
                    v-for="(it, index) of filteredAsistentes"
                    :key="it.id"
                    >{{ index + 1 }}.
                    {{ it.nombres }}
                    {{ it.apellidos }}</b-form-select-option
                  >
                </b-form-select>
                <div v-if="selectedItems.length" class="mt-2">
                  <b-button variant="outline-danger" @click="deselectAll"
                    >Deseleccionar Todos <i class="far fa-circle-xmark"></i
                  ></b-button>
                </div>
                <!-- campo de busquedad -->
                <input
                  v-model="filterText"
                  class="form-control mt-2"
                  placeholder="Buscar asistente"
                />

                <!-- Lista de elementos seleccionados -->
                <div class="selected-items">
                  <div
                    v-for="item in selectedItems"
                    :key="item.id"
                    class="selected-item"
                  >
                    {{ item.nombres }}
                    {{ item.apellidos }}
                    <span @click="removeSelectedItem(item)" class="remove-item">
                      <i class="fas fa-circle-xmark text-danger"></i>
                    </span>
                  </div>
                </div>
              </div>

              <!-- descripcion -->
              <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 16</em> -
                  Descripción Capacitación</label
                >
                <b-form-textarea
                  v-model="newCapacitacion.descripcion"
                  placeholder="Escriba una descripción..."
                  rows="7"
                  minlength="10"
                  maxlength="255"
                  required
                ></b-form-textarea>
              </div>

              <!-- certificacion -->
              <div class="col-xl-6 mb-3">
                <div>
                  <label for=" " class="form-label">
                    <em class="text-danger font-weight-bolder">Paso 17</em> -
                    Certificado Finalización</label
                  >
                  <!-- vue-select -->
                  <div style="background: transparent; padding: 0rem">
                    <v-select
                      v-model="newCapacitacion.certificacion"
                      class="style-chooser"
                      :reduce="(value) => value.value"
                      :options="optionsCertificacion"
                      :get-option-label="(option) => `${option.text}`"
                      placeholder="-- seleccionar una opción --"
                    >
                      <!-- required -->
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!newCapacitacion.certificacion"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <!-- sin resultados en la busquedad -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para
                          <em class="text-danger">{{ search }}</em
                          >.
                        </template>
                        <em v-else style="opacity: 0.5"
                          >No hay opciones para mostrar.</em
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>

              <!-- carga de archivos -->
              <!-- <div class="col-xl-6 mb-3">
                <label for=" " class="form-label"
                  ><em class="text-danger font-weight-bolder"
                    >Carga de Archivos</em
                  >
                  - Ejem: Contrato Capacitación</label
                >
                <div>
                  <b-button
                    class="position-relative rounded"
                    variant="outline-warning"
                    size="lg"
                    style="font-size: 1rem; border: 1px dashed #fe721c"
                    v-b-modal.fileUpload
                    >Cargar Documentos...
                    <i class="fas fa-folder-open" style="font-size: 1.5rem"></i>
                    <i
                      class="fas fa-exclamation-triangle position-absolute"
                      style="top: -5px; left: -8px; font-size: 1rem"
                    ></i>
                  </b-button>
                </div>
              </div> -->
            </div>
            <div class="d-flex justify-content-end px-5 pb-4">
              <!-- <b-button
                type="submit"
                variant="outline-success"
                class="mx-1 px-4 py-2"
              >
                <i v-if="isSaving" class="fas fa-spinner fa-spin-pulse"></i>
                {{
                  isSaving ? "Creando capacitacion..." : "Guardar Capacitación"
                }}
              </b-button> -->
              <vs-button type="submit" success icon>
                <i v-if="isSaving" class="fas fa-spinner fa-spin-pulse"></i>
                {{
                  isSaving ? "Creando capacitacion..." : "Guardar Capacitación"
                }}
              </vs-button>
            </div>
          </card>
        </b-col>
      </b-row>
    </form>

    <!-- modal cargar archivos -->
    <b-modal title="Carga de archivos" id="fileUpload" size="md" hide-footer>
      <h4 class="mb-2">Cargar archivo aqui</h4>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);

const Swal = require("sweetalert2");
export default {
  name: "RegistroCapacitaciones_agregar",
  data() {
    return {
      persona: {},
      listaPersonas: [],
      listaTipoDoc: [],
      ListaAreasCapacitacion: [],
      listaTiposEmpresa: [],
      listaModalidadCapacitacion: [],
      isSaving: null,
      filterText: "", // Texto de búsqueda
      selectedAsistentes: [], // Un arreglo para almacenar los asistentes seleccionados
      selectedItems: [], // Lista de elementos seleccionados
      optionsCertificacion: [
        {
          text: "No incluye certificación",
          value: 1,
        },
        {
          text: "Si incluye certificación",
          value: 2,
        },
      ],

      newCapacitacion: {
        nombre: "",
        descripcion: "",
        areasCapacitacionId: [],
        fechaInicio: "",
        fechaFin: "",
        duracion: "",
        nombreInstructor: "",
        empresaCapacitadora: "",
        tipoEmpresaId: "",
        numeroDocumentoEmpresa: "",
        tipoDocumentoEmpresaId: "",
        numeroContrato: "",
        fechaContrato: "",
        modalidadCapacitacionId: "",
        costoTotal: "",
        listaAsistentesId: [],
        disponibilidad: 1,
        motivoCancelacion: "",
        estadoCapacitacion: 0,
        createdAt: "",
        responsableId: "",
        estadoUploadFiles: 0,
        certificacion: null,
      },
      newPlanCapacitacionProgreso: {
        capacitacionId: "",
        asistenteId: "",
        confirmacionAsistente: 0,
        comentarioAsistente: "",
        calificacionCapacitacion: 0,
        asistenciaReal: 0,
        createdAt: "",
        estadoUploadCertificado: 0,
      },
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getPersonas();
    await this.getTipoDocumento();
    await this.getAreaCapacitacion();
    await this.getTipoEmpresaCapacitacion();
    await this.getModalidadCapacitacion();
    this.$isLoading(false);
  },
  computed: {
    // Filtrar la lista de personas según el texto de búsqueda
    filteredAsistentes() {
      const filterText = this.filterText.toLowerCase();
      return this.listaPersonas.filter((it) =>
        this.matchesFilter(it, filterText)
      );
    },
    shouldExpand() {
      return (
        this.newCapacitacion.areaId && this.newCapacitacion.areaId.length > 4
      );
    },
  },
  methods: {
    // Verificar si un elemento coincide con el filtro de búsqueda
    matchesFilter(persona, filterText) {
      const words = filterText.split(" ");
      const fullName = `${persona.nombres} ${persona.apellidos}`.toLowerCase();

      // Verificar si todas las palabras en el filtro están en el nombre completo
      return words.every((word) => fullName.includes(word));
    },
    // Agregar un elemento seleccionado a la lista, si no está presente
    addSelectedItem(item) {
      // Verificar si el elemento ya está presente en la lista
      if (!this.selectedItems.some((selected) => selected.id === item.id)) {
        this.selectedItems.push(item);
        console.log(this.selectedItems.map((item) => item.id).join());
      }
    },
    // Eliminar un elemento seleccionado de la lista
    removeSelectedItem(item) {
      const index = this.selectedItems.indexOf(item);
      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
    },
    // Manejar cambios en la selección
    onSelectedItemsChange() {
      // Iterar sobre los elementos seleccionados y agregarlos a selectedItems
      this.selectedAsistentes.forEach((selectedId) => {
        const selectedPerson = this.listaPersonas.find(
          (person) => person.id === selectedId
        );
        if (selectedPerson) {
          this.addSelectedItem(selectedPerson);
        }
      });
    },
    // Método para deseleccionar todos los elementos
    deselectAll() {
      this.selectedAsistentes = []; // Limpiar la selección
      this.selectedItems = []; // Limpiar la lista de elementos seleccionados
    },
    async saveData() {
      console.log("data", this.newCapacitacion);
      try {
        // let tempListaAsistentesId = this.newCapacitacion.listaAsistentesId;
        let tempListaAsistentesId = this.selectedItems.map((item) => item.id);
        console.log(tempListaAsistentesId);
        this.isSaving = true;
        let res = await this.$store.dispatch("hl_post", {
          path: "PlanRegistroCapacitaciones/",
          data: {
            ...this.newCapacitacion,
            areasCapacitacionId:
              this.newCapacitacion.areasCapacitacionId.join(),
            listaAsistentesId: tempListaAsistentesId.join(),
            createdAt: this.$moment
              .tz(new Date(), "America/Bogota")
              .format("YYYY-MM-DDTHH:mm"),
            responsableId: this.persona.id,
            certificacion: this.newCapacitacion.certificacion == 1 ? 0 : 1,
          },
        });
        if (res.id) {
          const promises = tempListaAsistentesId.map(async (asistenteId) => {
            const datos = {
              ...this.newPlanCapacitacionProgreso,
              capacitacionId: res.id,
              asistenteId: asistenteId,
              createdAt: this.$moment
                .tz(new Date(), "America/Bogota")
                .format("YYYY-MM-DDTHH:mm"),
            };

            try {
              const result = await this.createPlanCapacitacionProgreso(datos);
              return result;
            } catch (error) {
              console.error("Error for asistenteId:", asistenteId, error);
              return null; // Handle the error as needed
            }
          });

          await Promise.all(promises);

          this.isSaving = false;
          this.initFormPlanRegistroCapacitaciones();
          Swal.fire({
            title: "Listo!",
            text: "Registro almacenado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK, ver listado",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              console.log("confirmado");

              this.$router.push("/helex/Capacitaciones/RegistroCapacitaciones");
            }
          });
        }
      } catch (error) {
        console.log(error);
        return null; // Handle the error as needed
      }
    },
    initFormPlanRegistroCapacitaciones() {
      for (let prop in this.newCapacitacion) {
        if (prop === "listaAsistentesId" || prop === "areasCapacitacionId") {
          this.newCapacitacion[prop] = [];
        } else if (
          prop === "estadoCapacitacion" ||
          prop === "estadoUploadFiles"
        ) {
          this.newCapacitacion[prop] = 0;
        } else if (prop === "disponibilidad") {
          this.newCapacitacion[prop] = 1;
        } else if (prop === "certificacion") {
          this.newCapacitacion[prop] = null;
        } else {
          this.newCapacitacion[prop] = "";
        }
      }
    },
    async createPlanCapacitacionProgreso(newPlanCapacitacionProgreso) {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "PlanCapacitacionProgreso/CreateCapacitacionProgreso/",
          data: newPlanCapacitacionProgreso,
        });
        console.log(res);
        return res; //true o false
      } catch (error) {
        console.log("err", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "PlanYMejoramiento/GetListResultadoEvaluacionesByEmpresa/" +
            this.persona.empresa.id,
        });
        console.log("list resultadoEvaluaciones...", res);
        if (res.length > 0) {
          this.resultadoEvaluaciones = res;
        } else {
          this.resultadoEvaluaciones = [];
        }
      } catch (error) {
        this.resultadoEvaluaciones = [];
        console.log("err", error);
      }
    },
    async getTipoDocumento() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "TipoDocumento/ListTipoDocumento",
        });
        // console.log("------>", res);
        if (res.length > 0) {
          this.listaTipoDoc = res;
          ////console.log(res)
        } else {
          this.listaTipoDoc = [];
        }
      } catch (error) {
        this.listaTipoDoc = [];
        console.log("err", error);
      }
    },
    async getAreaCapacitacion() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "AreaCapacitacion/ListAreaCapacitacion",
        });
        // console.log("------>", res);
        if (res.length > 0) {
          this.ListaAreasCapacitacion = res;
          ////console.log(res)
        } else {
          this.ListaAreasCapacitacion = [];
        }
      } catch (error) {
        this.ListaAreasCapacitacion = [];
        console.log("err", error);
      }
    },
    async getTipoEmpresaCapacitacion() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "TipoEmpresaCapacitacion/ListTipoEmpresaCapacitacion",
        });
        // console.log("------>", res);
        if (res.length > 0) {
          this.listaTiposEmpresa = res;
          ////console.log(res)
        } else {
          this.listaTiposEmpresa = [];
        }
      } catch (error) {
        this.listaTiposEmpresa = [];
        console.log("err", error);
      }
    },
    async getModalidadCapacitacion() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "ModalidadCapacitacion/ListModalidadCapacitacion",
        });
        // console.log("------>", res);
        if (res.length > 0) {
          this.listaModalidadCapacitacion = res;
          ////console.log(res)
        } else {
          this.listaModalidadCapacitacion = [];
        }
      } catch (error) {
        this.listaModalidadCapacitacion = [];
        console.log("err", error);
      }
    },

    async getPersonas() {
      try {
        console.log(
          "get personas/////////////////////////////////////////////..."
        );
        let res = await this.$store.dispatch("hl_get", {
          path: `${
            this.persona.rol.nivel < 3
              ? "Persona/ListPersonaJefe/" + this.persona.id
              : "Persona/ListPersona/" + this.persona.empresa.id
          }`,
        });
        console.log("...listapersonas", res);
        if (res.length > 0) {
          this.listaPersonas = res;
        } else {
          this.listaPersonas = [];
        }
      } catch (error) {
        this.listaPersonas = [];
        console.log("err", error);
      }
    },
  },
};
</script>

<style scoped type="scss">
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");

/* Estilos para la lista de elementos seleccionados */
.selected-items {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
}

.selected-item {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #2e46cf;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.remove-item {
  margin-left: 5px;
  cursor: pointer;
}
</style>
